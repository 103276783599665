import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import NotesContainer from 'components/organisms/users/cases/NotesContainer'
import DocumentPdf from 'components/organisms/users/document/DocumentPdf'
import {
  deleteDocument,
  getDetailCase,
  getDetailDocument,
  postNewChats,
  updateDocumentNameDetailCase
} from 'api'
import { useAuth } from 'hooks'
import RenameModal from 'components/organisms/users/RenameModal'
import { errorObj } from 'utils/handleError'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import useExportAsDocx from 'hooks/useExportAsDocx'
import { useTranslation } from 'react-i18next'
import DeleteCaseDetailTableItem from 'components/organisms/users/DeleteCaseDetailTableItem'

const DocumentPage = () => {
  const { t } = useTranslation()
  const { handleExportDocument } = useExportAsDocx()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { getAccessToken } = useAuth()
  const [layoutInfo] = useOutletContext()
  const noteRef = useRef()
  const params = useParams()

  const [tabNote, setTabNote] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [docModal, setDocModal] = useState({
    type: '',
    show: false,
    data: null
  })

  useEffect(() => {
    if (!params?.caseId && !params?.id) {
      return navigate('/cases', { replace: true })
    }
  }, [])

  const { data, refetch } = useQuery({
    queryKey: ['document-detail', params?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getDetailDocument(getAccessToken(), params?.id)
      return res
    },
    enabled: params?.id !== undefined
  })

  const detailCase = useQuery({
    queryKey: ['document-detail-case', params?.caseId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getDetailCase(getAccessToken(), params?.caseId)
      return res
    },
    enabled: params?.caseId !== undefined
  })

  const onRename = async (data) => {
    try {
      await updateDocumentNameDetailCase(getAccessToken(), params?.id, data.renameValue)
      refetch()
      showSuccessMessage('Document renamed!')
    } catch (error) {
      const obj = errorObj(error)
      showErrorMessage(obj.resData.errors[0].message)
    }
  }

  const goToTheChatFromDocument = (row) => {
    confirmNewChatMutationFromDocument.mutate([row.id])
  }

  const confirmNewChatMutationFromDocument = useMutation({
    mutationKey: '',
    mutationFn: (selectedDocumentIds) => {
      return postNewChats(
        getAccessToken(),
        detailCase?.data?.resData?.data?.id ?? state?.caseId,
        selectedDocumentIds
      )
    },
    onSuccess: (res) => {
      navigate(
        `/cases/${detailCase?.data?.resData?.data?.id ?? state?.caseId}/chat/${
          res.resData.data.id
        }`,
        {
          state: {
            caseId: detailCase?.data?.resData?.data?.id ?? state?.caseId,
            caseName: detailCase?.data?.resData?.data?.name,
            chatData: res.resData.data
          }
        }
      )
    },
    onError: () => {
      showErrorMessage('Error create new chat, please try againsss.')
    }
  })

  const deletingDocument = useMutation({
    mutationKey: ['delete-document'],
    mutationFn: async (id) => await deleteDocument(getAccessToken(), id),
    onSuccess: () => {
      showSuccessMessage('Document deleted!')
      setDeleteModal(false)
      navigate(`/cases/${detailCase?.data?.resData?.data?.id ?? state?.caseId}`, {
        state: {
          caseId: detailCase?.data?.resData?.data?.id ?? state?.caseId,
          caseName: detailCase?.data?.resData?.data?.name
        }
      })
    }
  })

  return (
    <div className="overflow-hidden grid grid-cols-1">
      <div className="grid grid-cols-12 gap-4">
        <div className="w-full col-span-10">
          <DocumentPdf
            onDelete={() => setDeleteModal(true)}
            onOpenAsChat={() => goToTheChatFromDocument(data?.resData?.data ?? state?.document)}
            onExport={handleExportDocument.bind(this, data?.resData?.data ?? state?.document)}
            caseName={detailCase?.data?.resData?.data?.name ?? state?.caseName}
            bookmarks={data?.resData?.data?.bookmarks ?? []}
            filename={data?.resData?.data?.filename ?? state?.document?.filename}
            fileUrl={data?.resData?.data?.filepath ?? state?.document?.filepath}
            onAddNote={() => noteRef?.current?.addNewNote('Document', state?.document)}
            isEligible={data?.resData?.data?.is_eligible ?? state?.document?.is_eligible}
            onRename={() => {
              const file = data?.resData?.data?.filename ?? state?.document?.filename
              const filename = file.split('.')
              let name = filename.join('.')
              if (filename.length > 1) {
                name = filename.slice(0, filename.length - 1).join('.')
              }
              setDocModal({
                show: true,
                type: 'rename',
                data: {
                  ...(data?.resData?.data ?? state?.document),
                  name: name
                }
              })
            }}
          />
        </div>
        <NotesContainer
          ref={noteRef}
          setTabNote={setTabNote}
          height={layoutInfo}
          tabNote={tabNote}
          caseId={detailCase?.data?.resData?.data?.id ?? state?.caseId}
          caseName={detailCase?.data?.resData?.data?.name ?? state?.caseName}
        />
      </div>
      {/* <div className="overflow-y-scroll w-[400px] h-full pt-4 pl-6 pr-3">
      </div> */}
      {docModal?.type === 'rename' && (
        <RenameModal
          title={t('Rename document')}
          onClose={() =>
            setDocModal((prev) => {
              return {
                ...prev,
                show: false
              }
            })
          }
          show={docModal.show}
          data={docModal.data}
          onSubmit={(data) => onRename(data)}
        />
      )}
      {deleteModal && (
        <DeleteCaseDetailTableItem
          show={deleteModal}
          onDelete={() => deletingDocument.mutate(data?.resData?.data?.id ?? state?.document?.id)}
          item={'document'}
          closeModal={() => setDeleteModal(false)}
        />
      )}
    </div>
  )
}

export default DocumentPage
