export class Config {
  static REACT_APP_ENV = window.REACT_APP_ENV
  static REACT_APP_BASE_URL = window.REACT_APP_BASE_URL

  static REACT_APP_GROWTHBOOK_HOST = window.REACT_APP_GROWTHBOOK_HOST
  static REACT_APP_GROWTHBOOK_CLIENT_KEY = window.REACT_APP_GROWTHBOOK_CLIENT_KEY

  static REACT_APP_SENTRY_DSN = window.REACT_APP_SENTRY_DSN
  static REACT_APP_SENTRY_ENV = window.REACT_APP_SENTRY_ENV
  static REACT_APP_SENTRY_SAMPLE_RATE = window.REACT_APP_SENTRY_SAMPLE_RATE
  static NODE_ENV = window.NODE_ENV
}
